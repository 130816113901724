<template>
  <custom-callout
    class-main="rounded py-3 mt-3"
    :has-toggle="false"
  >
    <div class="row mx-auto">
      <div class="col-12 col-md p-0">
        <span class="data-title d-block">Fecha:</span>
        {{ $formatDate(data.date) }}
      </div>
      <div class="col-12 col-md p-0">
        <span class="data-title d-block">Hora de Entrada:</span>
        {{ $formatDate(data.date, $templateDate.hour) }}
      </div>
      <div class="col-12 col-md p-0">
        <span class="data-title d-block">Propósito:</span>
        {{ data.purpose | empty }}
      </div>
      <div class="col-12 col-md p-0">
        <span class="data-title d-block">Nombre Usuario:</span>
        {{ data.userName | empty }}
      </div>
      <div class="col-12 col-md p-0">
        <span class="data-title d-block">Rol Usuario:</span>
        {{ data.userRole | empty }}
      </div>
    </div>
  </custom-callout>
</template>

<script>
import CustomCallout from 'custom-callout';

export default {
  name: 'HistoryResult',
  components: {
    CustomCallout,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
