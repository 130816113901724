import { http } from '@/core/api/';

const URL = 'api/student/historyaccessfile';

export const getInitFilters = () =>
  http.get(`${URL}/initfilters`);

export const search = (params) =>
  http.get(`${URL}/search`, {
    params
  });