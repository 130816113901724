<template>
  <div>
    <page-header
      :actions="headerActionsConfig"
      left-side-class="col-12 col-md-7"
      :page="headerConfig"
      right-side-class="col-12 col-md-5"
    />
    <filter-manager
      ref="filterComponent"
      v-model="filters"
      class="mb-4"
      :display-advanced="false"
      :filters="filtersConfig"
      @search="onSearchHandler"
    />
    <custom-separator class="my-4" text="Resultados" />

    <div v-if="historyResults.length" class="history-result-wrapper">
      <template v-for="(item, index) of historyResults">
        <result-row
          :key="`history-result-${index}`"
          :data="item"
        />
      </template>
    </div>

    <custom-result-not-found v-else />

    <pagination
      v-if="pagination.totalItemCount > pagination.count"
      :data="pagination"
      show-text
      @pagination-go-page="goToPage"
    />
    <status-leyend :status-list="statusLeyendData" />
  </div>
</template>

<script>
import PageHeader from 'PageHeader';
import FilterManager from 'FilterManager';
import Pagination from 'pagination';
import CustomSeparator from 'custom-separator';
import CustomResultNotFound from 'custom-result-not-found';
import ResultRow from '@/views/students/history-access-file/components/ResultRow.vue';

import {
  getInitFilters as _getInitFilters,
  search as _search,
} from '@/modules/history-access-file/api';
import mixAlert from '@/mixins/alert.mixin';

const DEFAULT_PAGE_SIZE = 10;

export default {
  name: 'Main',
  mixins: [mixAlert],
  components: {
    PageHeader,
    FilterManager,
    Pagination,
    ResultRow,
    CustomSeparator,
    CustomResultNotFound,
  },
  data: () => ({
    filters: {
      academicYear: null,
      target: null,
    },
    collections: {
      academicYears: [],
      targets: [],
    },
    pagination: {
      currentPage: 1,
      totalItemCount: 0,
      pageCount: 1,
      count: 0,
      data: [],
    },
  }),
  computed: {
    headerConfig() {
      return {
        title: 'Historial Acceso Estudiante',
        icon: 'fas fa-history',
        routes: ['Inicio', 'Estudiante', 'Historial Acceso Estudiante'],
      };
    },
    headerActionsConfig() {
      return [
        {
          text: 'Regresar',
          icon: 'fas fa-arrow-circle-left fa-fw',
          variant: 'outline-success',
          click: () => this.$router.push({ name: 'home' }),
        },
      ];
    },
    filtersConfig() {
      return [
        {
          name: 'academicYear',
          component: 'CustomDropDown',
          options: this.collections.academicYears,
          placeholder: 'Año Académico',
          fieldName: 'name',
          fieldKey: 'id',
          clearable: true,
          class: 'col-12 col-md-5',
        },
        {
          name: 'target',
          component: 'CustomDropDown',
          options: this.collections.targets,
          placeholder: 'Propósito',
          fieldName: 'name',
          fieldKey: 'id',
          class: 'col-12 col-md-5',
          clearable: true,
        },
      ];
    },
    studentSieId() {
      return +this.$route.params.studentSieId;
    },
    historyResults() {
      return this.pagination?.data;
    },
  },
  async created() {
    await this.getFilters();
  },
  methods: {
    async getFilters() {
      const { data } = await _getInitFilters();
      this.collections = { ...data.collections };
      this.filters = { ...data.filters };
    },
    fillPayload(page) {
      const payload = {
        academicYearId: this.filters.academicYear?.id, 
        cardId: this.filters.target?.id,
        pageIndex: page,
        pageSize: DEFAULT_PAGE_SIZE,
      };

      return payload;
    },
    async onSearchHandler() {
      const pageIndex = 1;
      const params = this.fillPayload(pageIndex);
      await this.onSearch(params);
    },
    async goToPage(page) {
      const params = this.fillPayload(page)
      await this.onSearch(params);
    },
    async onSearch(params) {
      const { data } = await _search(params);
      this.pagination = { ...data };
    },
  },
};
</script>

<style></style>
